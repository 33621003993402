var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix hidden" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          key: _vm.key,
          staticClass: "editTable double",
          staticStyle: { width: "100%" },
          attrs: {
            data:
              _vm.list &&
              _vm.list.slice(
                (_vm.currentPage - 1) * _vm.pageSize,
                _vm.currentPage * _vm.pageSize
              ),
            border: ""
          }
        },
        [
          _vm._l(_vm.leaderFieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                fixed: "",
                prop: item.field,
                label: item.name,
                width: item.width
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.editModeSet.inputSet &&
                        scope.row.editModeSet.inputSet[item.field]
                          ? _c("el-input", {
                              staticClass: "w100p",
                              attrs: {
                                size: "small",
                                type: scope.row.editModeSet.inputSet[item.field]
                                  .type
                                  ? scope.row.editModeSet.inputSet[item.field]
                                      .type
                                  : "",
                                disabled:
                                  scope.row.editModeSet.inputSet[item.field]
                                    .disabled,
                                maxlength: scope.row.editModeSet.inputSet[
                                  item.field
                                ].maxlength
                                  ? scope.row.editModeSet.inputSet[item.field]
                                      .maxlength
                                  : "",
                                placeholder: scope.row.editModeSet.inputSet[
                                  item.field
                                ].placeholder
                                  ? scope.row.editModeSet.inputSet[item.field]
                                      .placeholder
                                  : ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fixedInputChange(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                },
                                blur: function($event) {
                                  return _vm.fixedInputBlur(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                },
                                focus: function($event) {
                                  return _vm.fixedInputFocus(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                }
                              },
                              nativeOn: {
                                "!paste": function($event) {
                                  return _vm.fixedInputPaste(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                }
                              },
                              model: {
                                value: scope.row[item.field],
                                callback: function($$v) {
                                  _vm.$set(scope.row, item.field, $$v)
                                },
                                expression: "scope.row[item.field]"
                              }
                            })
                          : _vm._e(),
                        scope.row.editModeSet.selectSet &&
                        scope.row.editModeSet.selectSet[item.field]
                          ? _c(
                              "el-select",
                              {
                                staticClass: "select_single w100p",
                                attrs: {
                                  size: "small",
                                  disabled:
                                    scope.row.editModeSet.selectSet[item.field]
                                      .disabled,
                                  placeholder: "请选择"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.fixedSelectChange(
                                      $event,
                                      scope.row,
                                      item
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row[item.field],
                                  callback: function($$v) {
                                    _vm.$set(scope.row, item.field, $$v)
                                  },
                                  expression: "scope.row[item.field]"
                                }
                              },
                              [
                                _c("el-option", {
                                  key: "",
                                  attrs: { label: "请选择", value: "" }
                                }),
                                _vm._l(
                                  scope.row[
                                    scope.row.editModeSet.selectSet[item.field]
                                      .selectListName
                                  ],
                                  function(selectItem) {
                                    return _c("el-option", {
                                      key: scope.row.editModeSet.selectSet[
                                        item.field
                                      ].value
                                        ? selectItem[
                                            scope.row.editModeSet.selectSet[
                                              item.field
                                            ].value
                                          ]
                                        : selectItem,
                                      attrs: {
                                        label: scope.row.editModeSet.selectSet[
                                          item.field
                                        ].label
                                          ? selectItem[
                                              scope.row.editModeSet.selectSet[
                                                item.field
                                              ].label
                                            ]
                                          : selectItem,
                                        value: scope.row.editModeSet.selectSet[
                                          item.field
                                        ].value
                                          ? selectItem[
                                              scope.row.editModeSet.selectSet[
                                                item.field
                                              ].value
                                            ]
                                          : selectItem
                                      }
                                    })
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        scope.row.editModeSet.numberInputSet &&
                        scope.row.editModeSet.numberInputSet[item.field]
                          ? _c(
                              "div",
                              { staticClass: "w100p display_ib" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w80p",
                                  attrs: {
                                    size: "small",
                                    precision: item.field_unit
                                      ? scope.row[item.field_unit] == "米" ||
                                        scope.row[item.field_unit] == "公斤"
                                        ? 2
                                        : 0
                                      : "",
                                    "controls-position": "right",
                                    min: 0
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.fixedNumberInputChange(
                                        $event,
                                        scope.row,
                                        item
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row[item.field],
                                    callback: function($$v) {
                                      _vm.$set(scope.row, item.field, $$v)
                                    },
                                    expression: "scope.row[item.field]"
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.field_unit
                                        ? scope.row[item.field_unit]
                                        : ""
                                    ) +
                                    "\n        "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.editModeSet.datePickerSet &&
                        scope.row.editModeSet.datePickerSet[item.field]
                          ? _c("el-date-picker", {
                              staticClass: "w100p",
                              attrs: {
                                size: "small",
                                "value-format": "yyyy-MM-dd",
                                disabled:
                                  scope.row.editModeSet.datePickerSet[
                                    item.field
                                  ].disabled,
                                type: "date",
                                placeholder: "选择日期"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.fixedDatePickerChange(
                                    $event,
                                    scope.row,
                                    item
                                  )
                                }
                              },
                              model: {
                                value: scope.row[item.field],
                                callback: function($$v) {
                                  _vm.$set(scope.row, item.field, $$v)
                                },
                                expression: "scope.row[item.field]"
                              }
                            })
                          : _vm._e(),
                        scope.row.editModeSet.textSet &&
                        scope.row.editModeSet.textSet[item.field]
                          ? _c(
                              "span",
                              {
                                staticClass: "text",
                                attrs: { title: scope.row[item.field] }
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row[item.field]) +
                                    _vm._s(
                                      item.field_unit
                                        ? " " + scope.row[item.field_unit]
                                        : ""
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._l(_vm.fieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { prop: item.field, label: item.name, width: item.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        item.isSingleField
                          ? _c(
                              "div",
                              { staticClass: "s_column" },
                              [
                                scope.row.editModeSet.inputSet &&
                                scope.row.editModeSet.inputSet[item.field]
                                  ? _c(
                                      "div",
                                      { staticClass: "w100p display_ib" },
                                      [
                                        _c("el-input", {
                                          class:
                                            item.field_unit &&
                                            scope.row[item.field_unit]
                                              ? "w70p"
                                              : "w100p",
                                          attrs: {
                                            size: "small",
                                            type: scope.row.editModeSet
                                              .inputSet[item.field].type
                                              ? scope.row.editModeSet.inputSet[
                                                  item.field
                                                ].type
                                              : "",
                                            disabled:
                                              scope.row.editModeSet.inputSet[
                                                item.field
                                              ].disabled,
                                            minlength: scope.row.editModeSet
                                              .inputSet[item.field].minlength
                                              ? scope.row.editModeSet.inputSet[
                                                  item.field
                                                ].minlength
                                              : "",
                                            maxlength: scope.row.editModeSet
                                              .inputSet[item.field].maxlength
                                              ? scope.row.editModeSet.inputSet[
                                                  item.field
                                                ].maxlength
                                              : "",
                                            placeholder: scope.row.editModeSet
                                              .inputSet[item.field].placeholder
                                              ? scope.row.editModeSet.inputSet[
                                                  item.field
                                                ].placeholder
                                              : ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.cInputChange(
                                                $event,
                                                scope.row,
                                                item
                                              )
                                            }
                                          },
                                          nativeOn: {
                                            "!paste": function($event) {
                                              return _vm.cInputPaste(
                                                $event,
                                                scope.row,
                                                item
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row[item.field],
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                item.field,
                                                $$v
                                              )
                                            },
                                            expression: "scope.row[item.field]"
                                          }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.field_unit
                                                ? scope.row[item.field_unit]
                                                : ""
                                            ) +
                                            "\n          "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.editModeSet.selectSet &&
                                scope.row.editModeSet.selectSet[item.field]
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "select_single w100p",
                                        attrs: {
                                          size: "small",
                                          disabled:
                                            scope.row.editModeSet.selectSet[
                                              item.field
                                            ].disabled,
                                          placeholder: "请选择"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.cSelectChange(
                                              $event,
                                              scope.row,
                                              item
                                            )
                                          },
                                          "visible-change": function($event) {
                                            return _vm.cSelectVisibleChange(
                                              $event,
                                              scope.row,
                                              item
                                            )
                                          }
                                        },
                                        model: {
                                          value: scope.row[item.field],
                                          callback: function($$v) {
                                            _vm.$set(scope.row, item.field, $$v)
                                          },
                                          expression: "scope.row[item.field]"
                                        }
                                      },
                                      [
                                        _c("el-option", {
                                          key: "",
                                          attrs: { label: "请选择", value: "" }
                                        }),
                                        _vm._l(
                                          scope.row[
                                            scope.row.editModeSet.selectSet[
                                              item.field
                                            ].selectListName
                                          ],
                                          function(selectItem) {
                                            return _c("el-option", {
                                              key: scope.row.editModeSet
                                                .selectSet[item.field].value
                                                ? selectItem[
                                                    scope.row.editModeSet
                                                      .selectSet[item.field]
                                                      .value
                                                  ]
                                                : selectItem,
                                              attrs: {
                                                label: scope.row.editModeSet
                                                  .selectSet[item.field].label
                                                  ? selectItem[
                                                      scope.row.editModeSet
                                                        .selectSet[item.field]
                                                        .label
                                                    ]
                                                  : selectItem,
                                                value: scope.row.editModeSet
                                                  .selectSet[item.field].value
                                                  ? selectItem[
                                                      scope.row.editModeSet
                                                        .selectSet[item.field]
                                                        .value
                                                    ]
                                                  : selectItem
                                              }
                                            })
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                scope.row.editModeSet.numberInputSet &&
                                scope.row.editModeSet.numberInputSet[item.field]
                                  ? _c(
                                      "div",
                                      { staticClass: "w100p display_ib" },
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w80p",
                                          attrs: {
                                            size: "small",
                                            precision: item.field_unit
                                              ? scope.row[item.field_unit] ==
                                                  "米" ||
                                                scope.row[item.field_unit] ==
                                                  "公斤"
                                                ? 2
                                                : 0
                                              : "2",
                                            disabled:
                                              scope.row.editModeSet
                                                .numberInputSet[item.field]
                                                .disabled,
                                            "controls-position": "right",
                                            min: 0
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.cNumberInputChange(
                                                $event,
                                                scope.row,
                                                item
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row[item.field],
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                item.field,
                                                $$v
                                              )
                                            },
                                            expression: "scope.row[item.field]"
                                          }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.field_unit
                                                ? scope.row[item.field_unit]
                                                : ""
                                            ) +
                                            "\n          "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.editModeSet.datePickerSet &&
                                scope.row.editModeSet.datePickerSet[item.field]
                                  ? _c("el-date-picker", {
                                      staticClass: "w100p",
                                      attrs: {
                                        size: "small",
                                        "value-format": "yyyy-MM-dd",
                                        disabled:
                                          scope.row.editModeSet.datePickerSet[
                                            item.field
                                          ].disabled,
                                        type: "date",
                                        placeholder: "选择日期"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.cDatePickerChange(
                                            $event,
                                            scope.row,
                                            item
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row[item.field],
                                        callback: function($$v) {
                                          _vm.$set(scope.row, item.field, $$v)
                                        },
                                        expression: "scope.row[item.field]"
                                      }
                                    })
                                  : _vm._e(),
                                scope.row.editModeSet.unitSet &&
                                scope.row.editModeSet.unitSet[item.field_1]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "display_ib",
                                        staticStyle: { height: "32px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "display_ib ellipsis w40p"
                                          },
                                          [
                                            _c("el-input-number", {
                                              staticClass: "w60p",
                                              attrs: {
                                                size: "small",
                                                precision: "0",
                                                "controls-position": "right",
                                                min: 0
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.cfUnitNumberInputChange(
                                                    $event,
                                                    scope.row,
                                                    item,
                                                    "first"
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row[item.field_1],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    item.field_1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row[item.field_1]"
                                              }
                                            }),
                                            _vm._v(" \n              "),
                                            _c(
                                              "span",
                                              { staticClass: "display_ib" },
                                              [_vm._v("包")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "display_ib ellipsis w40p"
                                          },
                                          [
                                            _c("el-input-number", {
                                              staticClass: "w60p",
                                              attrs: {
                                                size: "small",
                                                precision: "2",
                                                "controls-position": "right",
                                                min: 0
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.csUnitNumberInputChange(
                                                    $event,
                                                    scope.row,
                                                    item,
                                                    "second"
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row[item.field_2],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    item.field_2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row[item.field_2]"
                                              }
                                            }),
                                            _vm._v(" \n              "),
                                            _c(
                                              "span",
                                              { staticClass: "display_ib" },
                                              [_vm._v("米/包")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "meters display_ib w20p"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "count display_ib",
                                                attrs: {
                                                  title: scope.row[item.field_3]
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row[item.field_3]
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "display_ib v_top"
                                              },
                                              [_vm._v("米")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                scope.row.editModeSet.textSet &&
                                scope.row.editModeSet.textSet[item.field]
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "text",
                                        attrs: { title: scope.row[item.field] }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row[item.field]
                                              ? scope.row[item.field]
                                              : item.isQuantityField
                                              ? 0
                                              : ""
                                          ) +
                                            _vm._s(
                                              item.field_unit
                                                ? " " +
                                                    scope.row[item.field_unit]
                                                : ""
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._l(
                              scope.row[_vm.fieldsList[0].chlidListName],
                              function(cItem, cIndex) {
                                return _c(
                                  "div",
                                  { key: cIndex, staticClass: "t_column" },
                                  [
                                    cItem.editModeSet.inputSet &&
                                    cItem.editModeSet.inputSet[item.field]
                                      ? _c("el-input", {
                                          staticClass: "w100p",
                                          attrs: {
                                            size: "small",
                                            type: cItem.editModeSet.inputSet[
                                              item.field
                                            ].type
                                              ? cItem.editModeSet.inputSet[
                                                  item.field
                                                ].type
                                              : "",
                                            disabled:
                                              cItem.editModeSet.inputSet[
                                                item.field
                                              ].disabled,
                                            maxlength: cItem.editModeSet
                                              .inputSet[item.field].maxlength
                                              ? cItem.editModeSet.inputSet[
                                                  item.field
                                                ].maxlength
                                              : "",
                                            placeholder: cItem.editModeSet
                                              .inputSet[item.field].placeholder
                                              ? cItem.editModeSet.inputSet[
                                                  item.field
                                                ].placeholder
                                              : ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.inputChange(
                                                $event,
                                                cItem,
                                                item
                                              )
                                            },
                                            blur: function($event) {
                                              return _vm.inputBlur(
                                                $event,
                                                cItem,
                                                item
                                              )
                                            }
                                          },
                                          nativeOn: {
                                            "!paste": function($event) {
                                              return _vm.inputPaste(
                                                $event,
                                                cItem,
                                                item
                                              )
                                            }
                                          },
                                          model: {
                                            value: cItem[item.field],
                                            callback: function($$v) {
                                              _vm.$set(cItem, item.field, $$v)
                                            },
                                            expression: "cItem[item.field]"
                                          }
                                        })
                                      : _vm._e(),
                                    cItem.editModeSet.inputDoubleSet &&
                                    cItem.editModeSet.inputDoubleSet[
                                      item.field_1
                                    ]
                                      ? _c(
                                          "div",
                                          [
                                            _c("el-input", {
                                              staticClass: "w40p",
                                              attrs: {
                                                size: "small",
                                                disabled:
                                                  cItem.editModeSet.inputSet[
                                                    item.field_1
                                                  ].disabled,
                                                maxlength: cItem.editModeSet
                                                  .inputSet[item.field_1]
                                                  .maxlength
                                                  ? cItem.editModeSet.inputSet[
                                                      item.field_1
                                                    ].maxlength
                                                  : "",
                                                placeholder: cItem.editModeSet
                                                  .inputSet[item.field_1]
                                                  .placeholder
                                                  ? cItem.editModeSet.inputSet[
                                                      item.field_1
                                                    ].placeholder
                                                  : ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.sDoubleInputChange(
                                                    $event,
                                                    cItem,
                                                    item
                                                  )
                                                }
                                              },
                                              nativeOn: {
                                                "!paste": function($event) {
                                                  return _vm.sDoubleInputPaste(
                                                    $event,
                                                    cItem,
                                                    item
                                                  )
                                                }
                                              },
                                              model: {
                                                value: cItem[item.field_1],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    cItem,
                                                    item.field_1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "cItem[item.field_1]"
                                              }
                                            }),
                                            _vm._v(" — \n            "),
                                            _c("el-input", {
                                              staticClass: "w40p",
                                              attrs: {
                                                size: "small",
                                                disabled:
                                                  cItem.editModeSet.inputSet[
                                                    item.field_2
                                                  ].disabled,
                                                maxlength: cItem.editModeSet
                                                  .inputSet[item.field_2]
                                                  .maxlength
                                                  ? cItem.editModeSet.inputSet[
                                                      item.field_2
                                                    ].maxlength
                                                  : "",
                                                placeholder: cItem.editModeSet
                                                  .inputSet[item.field_2]
                                                  .placeholder
                                                  ? cItem.editModeSet.inputSet[
                                                      item.field_2
                                                    ].placeholder
                                                  : ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.eDoubleInputChange(
                                                    $event,
                                                    cItem,
                                                    item
                                                  )
                                                }
                                              },
                                              nativeOn: {
                                                "!paste": function($event) {
                                                  return _vm.eDoubleInputPaste(
                                                    $event,
                                                    cItem,
                                                    item
                                                  )
                                                }
                                              },
                                              model: {
                                                value: cItem[item.field_2],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    cItem,
                                                    item.field_2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "cItem[item.field_2]"
                                              }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.field_unit
                                                    ? cItem[item.field_unit]
                                                    : ""
                                                ) +
                                                "\n          "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    cItem.editModeSet.selectSet &&
                                    cItem.editModeSet.selectSet[item.field]
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "select_single w100p",
                                            attrs: {
                                              size: "small",
                                              disabled:
                                                cItem.editModeSet.selectSet[
                                                  item.field
                                                ].disabled,
                                              placeholder: "请选择"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.selectChange(
                                                  $event,
                                                  cItem,
                                                  item
                                                )
                                              },
                                              "visible-change": function(
                                                $event
                                              ) {
                                                return _vm.selectVisibleChange(
                                                  $event,
                                                  cItem,
                                                  item
                                                )
                                              }
                                            },
                                            model: {
                                              value: cItem[item.field],
                                              callback: function($$v) {
                                                _vm.$set(cItem, item.field, $$v)
                                              },
                                              expression: "cItem[item.field]"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              key: "",
                                              attrs: {
                                                label: "请选择",
                                                value: ""
                                              }
                                            }),
                                            _vm._l(
                                              cItem[
                                                cItem.editModeSet.selectSet[
                                                  item.field
                                                ].selectListName
                                              ],
                                              function(selectItem) {
                                                return _c("el-option", {
                                                  key: cItem.editModeSet
                                                    .selectSet[item.field].value
                                                    ? selectItem[
                                                        cItem.editModeSet
                                                          .selectSet[item.field]
                                                          .value
                                                      ]
                                                    : selectItem,
                                                  attrs: {
                                                    label: cItem.editModeSet
                                                      .selectSet[item.field]
                                                      .label
                                                      ? selectItem[
                                                          cItem.editModeSet
                                                            .selectSet[
                                                            item.field
                                                          ].label
                                                        ]
                                                      : selectItem,
                                                    value: cItem.editModeSet
                                                      .selectSet[item.field]
                                                      .value
                                                      ? selectItem[
                                                          cItem.editModeSet
                                                            .selectSet[
                                                            item.field
                                                          ].value
                                                        ]
                                                      : selectItem
                                                  }
                                                })
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    cItem.editModeSet.numberInputSet &&
                                    cItem.editModeSet.numberInputSet[item.field]
                                      ? _c(
                                          "div",
                                          { staticClass: "w100p display_ib" },
                                          [
                                            _c("el-input-number", {
                                              class:
                                                item.field_unit &&
                                                cItem[item.field_unit]
                                                  ? "w70p"
                                                  : "w100p",
                                              attrs: {
                                                size: "small",
                                                precision: item.field_unit
                                                  ? cItem[item.field_unit] ==
                                                      "米" ||
                                                    cItem[item.field_unit] ==
                                                      "公斤"
                                                    ? 2
                                                    : 0
                                                  : "2",
                                                "controls-position": "right",
                                                min: 0,
                                                max: cItem.editModeSet
                                                  .numberInputSet[item.field]
                                                  .max
                                                  ? cItem.editModeSet
                                                      .numberInputSet[
                                                      item.field
                                                    ].max
                                                  : cItem.editModeSet
                                                      .numberInputSet[
                                                      item.field
                                                    ].max === 0
                                                  ? 0
                                                  : "Infinity"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.numberInputChange(
                                                    $event,
                                                    cItem,
                                                    item
                                                  )
                                                }
                                              },
                                              model: {
                                                value: cItem[item.field],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    cItem,
                                                    item.field,
                                                    $$v
                                                  )
                                                },
                                                expression: "cItem[item.field]"
                                              }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.field_unit
                                                    ? cItem[item.field_unit]
                                                    : ""
                                                ) +
                                                "\n          "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    cItem.editModeSet.datePickerSet &&
                                    cItem.editModeSet.datePickerSet[item.field]
                                      ? _c("el-date-picker", {
                                          staticClass: "w100p",
                                          attrs: {
                                            size: "small",
                                            "value-format": "yyyy-MM-dd",
                                            disabled:
                                              cItem.editModeSet.datePickerSet[
                                                item.field
                                              ].disabled,
                                            type: "date",
                                            placeholder: "选择日期"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.datePickerChange(
                                                $event,
                                                cItem,
                                                item
                                              )
                                            }
                                          },
                                          model: {
                                            value: cItem[item.field],
                                            callback: function($$v) {
                                              _vm.$set(cItem, item.field, $$v)
                                            },
                                            expression: "cItem[item.field]"
                                          }
                                        })
                                      : _vm._e(),
                                    cItem.editModeSet.unitSet &&
                                    cItem.editModeSet.unitSet[item.field_1]
                                      ? _c(
                                          "div",
                                          { staticStyle: { height: "32px" } },
                                          [
                                            cItem[item.field_unit] == "米"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "display_ib" },
                                                  [
                                                    item.isUnitSetText
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "display_ib",
                                                            attrs: {
                                                              title:
                                                                (cItem[
                                                                  item.field_1
                                                                ]
                                                                  ? cItem[
                                                                      item
                                                                        .field_1
                                                                    ]
                                                                  : "0") +
                                                                "包 " +
                                                                (cItem[
                                                                  item.field_2
                                                                ]
                                                                  ? cItem[
                                                                      item
                                                                        .field_2
                                                                    ]
                                                                  : "0") +
                                                                "米/包 " +
                                                                (cItem[
                                                                  item.field_3
                                                                ]
                                                                  ? cItem[
                                                                      item
                                                                        .field_3
                                                                    ]
                                                                  : "0") +
                                                                "米"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                (cItem[
                                                                  item.field_1
                                                                ]
                                                                  ? cItem[
                                                                      item
                                                                        .field_1
                                                                    ]
                                                                  : "0") +
                                                                  "包 " +
                                                                  (cItem[
                                                                    item.field_2
                                                                  ]
                                                                    ? cItem[
                                                                        item
                                                                          .field_2
                                                                      ]
                                                                    : "0") +
                                                                  "米/包 " +
                                                                  (cItem[
                                                                    item.field_3
                                                                  ]
                                                                    ? cItem[
                                                                        item
                                                                          .field_3
                                                                      ]
                                                                    : "0") +
                                                                  "米"
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "display_ib"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "display_ib ellipsis w40p"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticClass:
                                                                      "w60p",
                                                                    attrs: {
                                                                      size:
                                                                        "small",
                                                                      precision:
                                                                        "0",
                                                                      "controls-position":
                                                                        "right",
                                                                      min: 0,
                                                                      max: cItem
                                                                        .editModeSet
                                                                        .unitSet[
                                                                        item
                                                                          .field_1
                                                                      ].max
                                                                        ? cItem
                                                                            .editModeSet
                                                                            .unitSet[
                                                                            item
                                                                              .field_1
                                                                          ].max
                                                                        : cItem
                                                                            .editModeSet
                                                                            .unitSet[
                                                                            item
                                                                              .field_1
                                                                          ]
                                                                            .max ===
                                                                          0
                                                                        ? 0
                                                                        : "Infinity"
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.ftUnitNumberInputChange(
                                                                          $event,
                                                                          cItem,
                                                                          item,
                                                                          "first"
                                                                        )
                                                                      }
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        cItem[
                                                                          item
                                                                            .field_1
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          cItem,
                                                                          item.field_1,
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "cItem[item.field_1]"
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(
                                                                  " \n                  "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "display_ib"
                                                                  },
                                                                  [_vm._v("包")]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "display_ib ellipsis w40p"
                                                              },
                                                              [
                                                                (cItem
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].type ===
                                                                "select"
                                                                ? true
                                                                : false)
                                                                  ? _c(
                                                                      "el-select",
                                                                      {
                                                                        staticClass:
                                                                          "select_single w60p",
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          disabled: cItem
                                                                            .editModeSet
                                                                            .unitSet[
                                                                            item
                                                                              .field_2
                                                                          ]
                                                                            ? cItem
                                                                                .editModeSet
                                                                                .unitSet[
                                                                                item
                                                                                  .field_2
                                                                              ]
                                                                                .disabled
                                                                            : "",
                                                                          placeholder:
                                                                            "请选择"
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.unitSetSelectChange(
                                                                              $event,
                                                                              cItem,
                                                                              item
                                                                            )
                                                                          },
                                                                          "visible-change": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.unitSetSelectVisibleChange(
                                                                              $event,
                                                                              cItem,
                                                                              item
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            cItem[
                                                                              item
                                                                                .field_2
                                                                            ],
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              cItem,
                                                                              item.field_2,
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "cItem[item.field_2]"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-option",
                                                                          {
                                                                            key:
                                                                              "",
                                                                            attrs: {
                                                                              label:
                                                                                "请选择",
                                                                              value:
                                                                                ""
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._l(
                                                                          cItem[
                                                                            cItem
                                                                              .editModeSet
                                                                              .selectSet[
                                                                              item
                                                                                .field_2
                                                                            ]
                                                                              .selectListName
                                                                          ],
                                                                          function(
                                                                            selectItem
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: cItem
                                                                                  .editModeSet
                                                                                  .selectSet[
                                                                                  item
                                                                                    .field_2
                                                                                ]
                                                                                  .value
                                                                                  ? selectItem[
                                                                                      cItem
                                                                                        .editModeSet
                                                                                        .selectSet[
                                                                                        item
                                                                                          .field_2
                                                                                      ]
                                                                                        .value
                                                                                    ]
                                                                                  : selectItem,
                                                                                attrs: {
                                                                                  label: cItem
                                                                                    .editModeSet
                                                                                    .selectSet[
                                                                                    item
                                                                                      .field_2
                                                                                  ]
                                                                                    .label
                                                                                    ? selectItem[
                                                                                        cItem
                                                                                          .editModeSet
                                                                                          .selectSet[
                                                                                          item
                                                                                            .field_2
                                                                                        ]
                                                                                          .label
                                                                                      ]
                                                                                    : selectItem,
                                                                                  value: cItem
                                                                                    .editModeSet
                                                                                    .selectSet[
                                                                                    item
                                                                                      .field_2
                                                                                  ]
                                                                                    .value
                                                                                    ? selectItem[
                                                                                        cItem
                                                                                          .editModeSet
                                                                                          .selectSet[
                                                                                          item
                                                                                            .field_2
                                                                                        ]
                                                                                          .value
                                                                                      ]
                                                                                    : selectItem
                                                                                }
                                                                              }
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  : _c(
                                                                      "el-input-number",
                                                                      {
                                                                        staticClass:
                                                                          "w60p",
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          precision:
                                                                            "2",
                                                                          "controls-position":
                                                                            "right",
                                                                          min: 0
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.stUnitNumberInputChange(
                                                                              $event,
                                                                              cItem,
                                                                              item,
                                                                              "second"
                                                                            )
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            cItem[
                                                                              item
                                                                                .field_2
                                                                            ],
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              cItem,
                                                                              item.field_2,
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "cItem[item.field_2]"
                                                                        }
                                                                      }
                                                                    ),
                                                                _vm._v(
                                                                  " \n                  "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "display_ib"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "米/包"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "meters display_ib w20p"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "count display_ib",
                                                                    attrs: {
                                                                      title:
                                                                        cItem[
                                                                          item
                                                                            .field_3
                                                                        ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        cItem[
                                                                          item
                                                                            .field_3
                                                                        ]
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "display_ib v_top"
                                                                  },
                                                                  [_vm._v("米")]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "display_ib" },
                                                  [
                                                    item.isUnitSetText
                                                      ? _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title: cItem[
                                                                item.field
                                                              ]
                                                                ? cItem[
                                                                    item.field
                                                                  ]
                                                                : "0"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                cItem[
                                                                  item.field
                                                                ]
                                                                  ? cItem[
                                                                      item.field
                                                                    ]
                                                                  : "0"
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _c("el-input-number", {
                                                          staticClass: "w100",
                                                          attrs: {
                                                            size: "small",
                                                            "controls-position":
                                                              "right",
                                                            precision:
                                                              cItem[
                                                                item.field_unit
                                                              ] == "公斤"
                                                                ? 2
                                                                : 0,
                                                            min: cItem
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_1
                                                            ].min
                                                              ? cItem
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].min
                                                              : 0,
                                                            max: cItem
                                                              .editModeSet
                                                              .unitSet[
                                                              item.field_1
                                                            ].max
                                                              ? cItem
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].max
                                                              : cItem
                                                                  .editModeSet
                                                                  .unitSet[
                                                                  item.field_1
                                                                ].max === 0
                                                              ? 0
                                                              : "Infinity"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.unitNumberInputChange(
                                                                $event,
                                                                cItem,
                                                                item
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              cItem[item.field],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                cItem,
                                                                item.field,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "cItem[item.field]"
                                                          }
                                                        }),
                                                    _vm._v(" \n              "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "display_ib"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            cItem[
                                                              item.field_unit
                                                            ]
                                                              ? cItem[
                                                                  item
                                                                    .field_unit
                                                                ]
                                                              : "条"
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                          ]
                                        )
                                      : _vm._e(),
                                    cItem.editModeSet.textSet &&
                                    cItem.editModeSet.textSet[item.field]
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text",
                                            attrs: { title: cItem[item.field] }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                cItem[item.field]
                                                  ? cItem[item.field]
                                                  : item.isQuantityField
                                                  ? 0
                                                  : ""
                                              ) +
                                                _vm._s(
                                                  item.field_unit
                                                    ? " " +
                                                        cItem[item.field_unit]
                                                    : ""
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }
                            )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _vm._l(_vm.tailFieldsList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: { fixed: "right", label: item.name, width: item.width },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return item.isAction
                        ? [
                            item.isChlidAction
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "action_wrap inline_block mr10"
                                  },
                                  _vm._l(
                                    scope.row[_vm.fieldsList[0].chlidListName],
                                    function(cItem, cIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: cIndex,
                                          staticClass: "action block"
                                        },
                                        [
                                          item.hasChildDelete
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.deleteChlidHandle(
                                                        (_vm.currentPage - 1) *
                                                          _vm.pageSize +
                                                          scope.$index,
                                                        cIndex,
                                                        cItem
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                          item.hasChildAdd
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.addChlidHandle(
                                                        (_vm.currentPage - 1) *
                                                          _vm.pageSize +
                                                          scope.$index,
                                                        cIndex,
                                                        cItem
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("添加")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            item.hasCheck
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.checkHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                              : _vm._e(),
                            item.hasAdd
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        item.hasAdd && item.hasAdd.disabled,
                                      size: "small"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.addHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                            item.hasEdit
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.editHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            item.hasSubmit
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.submitHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("提交审批")]
                                )
                              : _vm._e(),
                            item.hasRemove
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.removeHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("整单解除")]
                                )
                              : _vm._e(),
                            item.hasPay
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.payHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("发起付款")]
                                )
                              : _vm._e(),
                            item.hasDelete
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.deleteHandle(
                                          (_vm.currentPage - 1) * _vm.pageSize +
                                            scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        : undefined
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination_wrap fr mt10" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.pageSize,
              layout: "sizes, total, prev, pager, next",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
              "update:currentPage": function($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function($event) {
                _vm.currentPage = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }