var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transferOrder container" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("调拨单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.factoryAllocationCode,
                        callback: function($$v) {
                          _vm.factoryAllocationCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "factoryAllocationCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("确认书号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.confirmNumber,
                        callback: function($$v) {
                          _vm.confirmNumber =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "confirmNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("调拨类型：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.allocationType,
                          callback: function($$v) {
                            _vm.allocationType =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "allocationType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.transferTypeList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierCode,
                          callback: function($$v) {
                            _vm.supplierCode =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "supplierCode"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.supplierList, function(item) {
                          return _c("el-option", {
                            key: item.supplierId,
                            attrs: {
                              label: item.supplierCode,
                              value: item.supplierId
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("出库仓位：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.virtualInventoryChange },
                        model: {
                          value: _vm.virtualInventoryId,
                          callback: function($$v) {
                            _vm.virtualInventoryId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "virtualInventoryId"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.warehouseList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("出库库位：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.subInventory,
                          callback: function($$v) {
                            _vm.subInventory =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "subInventory"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.outSubInventoryList, function(item) {
                          return _c("el-option", {
                            key: item.subInventory,
                            attrs: {
                              label: item.subInventoryName,
                              value: item.subInventory
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("入库仓位：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.inputInventoryChange },
                        model: {
                          value: _vm.inputInventoryId,
                          callback: function($$v) {
                            _vm.inputInventoryId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "inputInventoryId"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.warehouseList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("入库库位：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.inputSubInventory,
                          callback: function($$v) {
                            _vm.inputSubInventory =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "inputSubInventory"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.inSubInventoryList, function(item) {
                          return _c("el-option", {
                            key: item.subInventory,
                            attrs: {
                              label: item.subInventoryName,
                              value: item.subInventory
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("期望交期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.excelDownload }
                    },
                    [_vm._v("导出Excel")]
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addHandle("transferOrder")
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap hidden",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.transferList,
                              "row-key": "id",
                              "expand-row-keys": _vm.expands,
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            ),
                                            scope.row.allocationStatus ==
                                              "Draft" ||
                                            scope.row.allocationStatus ==
                                              "Rejected"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "fr mr10",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editHandle(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("更新导入Excel")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "pl-table",
                                          {
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              data: scope.row.faItemList,
                                              "max-height": 700,
                                              "use-virtual": true,
                                              "row-height": 90,
                                              border: "",
                                              fit: ""
                                            }
                                          },
                                          [
                                            _c("pl-table-column", {
                                              attrs: { label: "行号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.lineNum
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: {
                                                label: "物料编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao",
                                                "show-overflow-tooltip": true
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.materialCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "产品编号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "销售订单编号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.saleOrderId
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "确认书号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.confirmNumbers
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "调拨数量" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        row.purchaseMeasurementUnit ==
                                                        "米"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (row.allocationPackages
                                                                    ? row.allocationPackages
                                                                    : 0) +
                                                                    "包 " +
                                                                    (row.allocationQuantityPerPackage
                                                                      ? row.allocationQuantityPerPackage
                                                                      : 0) +
                                                                    "米/包 " +
                                                                    (row.allocationQuantity
                                                                      ? row.allocationQuantity
                                                                      : 0) +
                                                                    "米"
                                                                )
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (row.allocationQuantity
                                                                    ? row.allocationQuantity
                                                                    : 0) +
                                                                    (row.purchaseMeasurementUnit
                                                                      ? row.purchaseMeasurementUnit
                                                                      : "条")
                                                                )
                                                              )
                                                            ])
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "出库仓位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.virtualInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "出库库位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.subInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "入库仓位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inputInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "入库库位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inputSubInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "已入库数量" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.actualStorageQuantity
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "期望交期" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.validDate
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "付款方式" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.paymentMethodName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("pl-table-column", {
                                              attrs: { label: "备注" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(row.remark) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "调拨单编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.factoryAllocationCode) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "调拨类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.allocationTypeName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "客户名称" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        row.isCustomerOrderType
                                          ? _c("span", [
                                              _vm._v(_vm._s(row.customerName))
                                            ])
                                          : _c("span")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "供应商" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.supplierShortName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "预计到货日期" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.expectedArrivalDate) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "发运方式" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        row.isFactoryTransferType
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(row.deliveryTypeName)
                                              )
                                            ])
                                          : _c("span")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.createTime) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.applicantName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "300",
                                              trigger: "hover",
                                              "open-delay": "1000"
                                            },
                                            on: {
                                              show: function($event) {
                                                return _vm.checkApprovalProcess(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批人：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.fullName))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "block mt10 mb10"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      审批时间：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      _vm._s(row.approvalTime)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批意见：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.content))
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "pointer",
                                                attrs: {
                                                  slot: "reference",
                                                  type: _vm._f("statusFilter")(
                                                    row.allocationStatus
                                                  )
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.allocationStatusName
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.allocationStatus == "Draft" ||
                                        row.allocationStatus == "Rejected"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row,
                                                        "edit"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-check",
                                                  attrs: {
                                                    type: "success",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "提交审批"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.submitHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.allocationStatus == "Approved"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-document-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "撤销",
                                                    disabled: row.cancelled
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.revokeHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-connection",
                                                  attrs: {
                                                    type: "warning",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "裂变",
                                                    disabled:
                                                      row.cancelled ||
                                                      !row.isCanFission
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row,
                                                        "fission"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.allocationStatus == "Close"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass:
                                                    "el-icon-switch-button",
                                                  attrs: {
                                                    type: "danger",
                                                    disabled: "",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "关闭"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.allocationStatus == "Terminated"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c(
                "div",
                { staticClass: "clearfix mb10" },
                [
                  this.editDifferentPrType != "differencePr"
                    ? _c(
                        "div",
                        { staticClass: "inline_block mr10 mb10" },
                        [
                          _vm._m(0),
                          _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10 mb10",
                              attrs: {
                                disabled: !_vm.isEmptyData,
                                placeholder: "请选择"
                              },
                              on: { change: _vm.allocationTypeChange },
                              model: {
                                value: _vm.editData.allocationType,
                                callback: function($$v) {
                                  _vm.$set(_vm.editData, "allocationType", $$v)
                                },
                                expression: "editData.allocationType"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择", value: "" }
                              }),
                              _vm._l(_vm.transferTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.index,
                                  attrs: { label: item.name, value: item.index }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCustomerOrder &&
                  this.editDifferentPrType != "differencePr"
                    ? _c(
                        "div",
                        { staticClass: "inline_block mr10 mb10" },
                        [
                          _vm._m(1),
                          _c("el-input", {
                            staticClass: "input_single w200 mr10 mb10",
                            attrs: {
                              disabled: !_vm.isEmptyData,
                              placeholder: "请输入"
                            },
                            model: {
                              value: _vm.editData.saleOrderId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editData,
                                  "saleOrderId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "editData.saleOrderId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  this.editDifferentPrType != "differencePr"
                    ? _c("el-button", {
                        staticClass: "mr10 mb10",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-refresh-right",
                          circle: "",
                          title: "拉取数据",
                          disabled: !_vm.isEmptyData || _vm.isDisabledPull
                        },
                        on: { click: _vm.pullDataHandle }
                      })
                    : _vm._e(),
                  _vm.isCustomerOrder &&
                  this.editDifferentPrType != "differencePr"
                    ? _c("div", { staticClass: "inline_block mr10" }, [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("客户名称：")
                        ]),
                        _c("span", { staticClass: "tag" }, [
                          _vm._v(_vm._s(_vm.editData.customerName))
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "inline_block mr10 mb10" },
                    [
                      _vm._m(2),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10",
                          attrs: {
                            filterable: "",
                            disabled: _vm.isDisabledSupplier,
                            placeholder: "请选择"
                          },
                          on: { change: _vm.supplierChange },
                          model: {
                            value: _vm.editData.supplierId,
                            callback: function($$v) {
                              _vm.$set(_vm.editData, "supplierId", $$v)
                            },
                            expression: "editData.supplierId"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "请选择", value: "" }
                          }),
                          _vm._l(_vm.supplierList, function(item) {
                            return _c("el-option", {
                              key: item.supplierId,
                              attrs: {
                                label: item.supplierCode,
                                value: item.supplierId
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  this.editDifferentPrType != "differencePr"
                    ? _c(
                        "div",
                        { staticClass: "inline_block mr10 mb10" },
                        [
                          _vm._m(3),
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择日期"
                            },
                            model: {
                              value: _vm.editData.expectedArrivalDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editData,
                                  "expectedArrivalDate",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "editData.expectedArrivalDate"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFactoryAllocation &&
                  this.editDifferentPrType != "differencePr"
                    ? _c(
                        "div",
                        { staticClass: "inline_block mr10 mb10" },
                        [
                          _c("span", { staticClass: "tag" }, [
                            _vm._v("发运方式：")
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "select_single w200 mr10 mb10",
                              attrs: {
                                disabled: !_vm.isEmptyData,
                                placeholder: "请选择"
                              },
                              on: { change: _vm.deliveryTypeChange },
                              model: {
                                value: _vm.editData.deliveryType,
                                callback: function($$v) {
                                  _vm.$set(_vm.editData, "deliveryType", $$v)
                                },
                                expression: "editData.deliveryType"
                              }
                            },
                            [
                              _c("el-option", {
                                key: "",
                                attrs: { label: "请选择", value: "" }
                              }),
                              _vm._l(_vm.deliveryTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.index,
                                  attrs: { label: item.name, value: item.index }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "link inline_block lh36 fr ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportExcelTemplate }
                    },
                    [_vm._v("调拨导入模板")]
                  ),
                  _c("el-button", {
                    staticClass: "fr",
                    attrs: {
                      disabled: _vm.fissionstatus,
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addEditHandle }
                  })
                ],
                1
              ),
              !_vm.fissionstatus
                ? _c(
                    "div",
                    [
                      _c("upload-excel-component", {
                        staticClass: "mb20",
                        attrs: {
                          "on-success": _vm.importExcelSuccess,
                          "before-upload": _vm.importExcelBeforeUpload
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "hidden" },
                [
                  _c("editDoubleTable", {
                    ref: "editDoubleTable",
                    attrs: {
                      tableList: _vm.editData.faItemList,
                      total:
                        _vm.editData.faItemList &&
                        _vm.editData.faItemList.length
                          ? _vm.editData.faItemList.length
                          : 0,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.editFieldsList
                    },
                    on: {
                      inputChange: _vm.inputChange,
                      inputBlur: _vm.inputBlur,
                      selectChange: _vm.selectChange,
                      selectVisibleChange: _vm.selectVisibleChange,
                      unitNumberInputChange: _vm.unitNumberInputChange,
                      deleteChlidHandle: _vm.deleteEditItemHandle,
                      addHandle: _vm.addEditItemHandle
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "edit_btn_wrap mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.cancelEditHandle($event)
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateEditHandle("save")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      ),
                      !_vm.fissionstatus &&
                      this.editDifferentPrType != "differencePr"
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "preventReClick",
                                  rawName: "v-preventReClick"
                                }
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.updateEditHandle("submit")
                                }
                              }
                            },
                            [_vm._v("提交审批")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("调拨类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("销售订单编号：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("供应商：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预计到货日期：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }