var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "提示",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "before-close": _vm.cancelHandle,
        width: "30%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.dialogDesc))]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.cancelHandle($event)
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirmHandle($event)
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }