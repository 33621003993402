var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "factoryorder" },
    [
      _vm.ifaddsure == false
        ? _c("div", { staticClass: "commoncontent" }, [
            _vm.activeName === "5"
              ? _c(
                  "div",
                  { staticClass: "container_title clearfix" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择请求类型" },
                        model: {
                          value: _vm.purchaseType,
                          callback: function($$v) {
                            _vm.purchaseType = $$v
                          },
                          expression: "purchaseType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择请求类型", value: "" }
                        }),
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemValue
                            }
                          })
                        })
                      ],
                      2
                    ),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "调拨单号" },
                      model: {
                        value: _vm.factoryAllocationCode,
                        callback: function($$v) {
                          _vm.factoryAllocationCode = $$v
                        },
                        expression: "factoryAllocationCode"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "down fr" },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-search",
                            circle: "",
                            title: "搜索"
                          },
                          on: { click: _vm.receiptSearchHandle }
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "medium",
                            type: "primary",
                            icon: "el-icon-upload",
                            circle: "",
                            title: "上传"
                          },
                          on: { click: _vm.receiptUploadHandle }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "container_title clearfix" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择请求类型" },
                        model: {
                          value: _vm.typestatusid,
                          callback: function($$v) {
                            _vm.typestatusid = $$v
                          },
                          expression: "typestatusid"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemValue
                            }
                          })
                        })
                      ],
                      2
                    ),
                    _c("el-input", {
                      staticClass: "input_single w150 ml10 mr10",
                      attrs: { placeholder: "调拨单编号" },
                      model: {
                        value: _vm.orderOrRequestCode,
                        callback: function($$v) {
                          _vm.orderOrRequestCode = $$v
                        },
                        expression: "orderOrRequestCode"
                      }
                    }),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.value1,
                        callback: function($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "down fr" },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-search",
                            circle: "",
                            title: "搜索"
                          },
                          on: { click: _vm.searchHandle }
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "medium",
                            type: "primary",
                            icon: "el-icon-upload",
                            circle: "",
                            title: "上传"
                          },
                          on: { click: _vm.uploadHandle }
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-download",
                            circle: "",
                            title: "下载"
                          },
                          on: { click: _vm.downloadHandle }
                        }),
                        _c("el-button", {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-plus",
                            circle: "",
                            title: "添加"
                          },
                          on: { click: _vm.byallocating }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
            _c(
              "div",
              { staticClass: "container_table clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  [
                    _vm._l(_vm.panelist, function(item) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: item.index,
                          attrs: { label: item.name, name: item.index }
                        },
                        [
                          _c("ul", { staticClass: "childlist" }, [
                            _c("li", { staticClass: "w13p" }, [
                              _vm._v("产品编号")
                            ]),
                            _c("li", { staticClass: "w13p" }, [
                              _vm._v("物料编号")
                            ]),
                            _c("li", { staticClass: "w13p" }, [
                              _vm._v("预计到货日期")
                            ]),
                            _c("li", { staticClass: "w18p" }, [
                              _vm._v("调拨数量")
                            ]),
                            _c("li", { staticClass: "w13p" }, [_vm._v("仓位")]),
                            _c("li", { staticClass: "w18p" }, [_vm._v("库位")]),
                            _c("li", { staticClass: "w13p" }, [
                              _vm._v("销售订单编号")
                            ]),
                            _c("li", { staticClass: "w13p" }, [_vm._v("备注")])
                          ]),
                          _vm._l(_vm.requestList, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "allparentlist" },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "parentlist",
                                    on: {
                                      click: function($event) {
                                        return _vm.showlist(index, item)
                                      }
                                    }
                                  },
                                  [
                                    _c("li", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.createTime))
                                      ])
                                    ]),
                                    _c("li", [
                                      _c("i", [
                                        _vm._v(
                                          "\n                    调拨单编号:\n                    "
                                        ),
                                        _c("em", [
                                          _vm._v(
                                            _vm._s(item.factoryAllocationCode)
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("li", [
                                      _c("i", [
                                        _vm._v(
                                          "\n                    调拨类型:\n                    "
                                        ),
                                        _c("em", [
                                          _vm._v(
                                            _vm._s(item.allocationTypeName)
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("li", [
                                      _c("i", [
                                        _vm._v(
                                          "\n                    调拨状态:\n                    "
                                        ),
                                        item.allocationStatus == "Draft"
                                          ? _c(
                                              "em",
                                              { attrs: { title: "草稿" } },
                                              [_vm._v("草稿")]
                                            )
                                          : _vm._e(),
                                        item.allocationStatus == "Approving"
                                          ? _c(
                                              "em",
                                              { attrs: { title: "审批中" } },
                                              [_vm._v("审批中")]
                                            )
                                          : _vm._e(),
                                        item.allocationStatus == "Approved"
                                          ? _c(
                                              "em",
                                              { attrs: { title: "已完成" } },
                                              [_vm._v("已完成")]
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _c(
                                      "li",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          "margin-right": "15px"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.showlist(index, item)
                                          }
                                        }
                                      },
                                      [
                                        item.ifshowlist == true
                                          ? _c("i", {
                                              staticClass: "el-icon-caret-top"
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-caret-bottom"
                                            })
                                      ]
                                    ),
                                    item.allocationStatus == "Draft"
                                      ? _c(
                                          "li",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-thumb",
                                              attrs: { title: "提交审批" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.draftoutsave(item)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    item.allocationStatus == "Draft"
                                      ? _c(
                                          "li",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              attrs: { title: "删除" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.factorydel(item)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    item.allocationStatus == "Draft"
                                      ? _c(
                                          "li",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-edit",
                                              attrs: { title: "编辑" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.factoryupd(item)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._l(item.faItemList, function(
                                  childItem,
                                  index
                                ) {
                                  return _c(
                                    "ul",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.ifshowlist == true,
                                          expression: "item.ifshowlist==true"
                                        }
                                      ],
                                      key: index,
                                      staticClass: "childcontentlist"
                                    },
                                    [
                                      _c(
                                        "li",
                                        {
                                          staticClass: "w13p",
                                          attrs: {
                                            title: childItem.productCode
                                          }
                                        },
                                        [_vm._v(_vm._s(childItem.productCode))]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "w13p",
                                          attrs: {
                                            title: childItem.materialCode
                                          }
                                        },
                                        [_vm._v(_vm._s(childItem.materialCode))]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "w13p",
                                          attrs: {
                                            title: childItem.offeredDate
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              childItem.expectedArrivalDate
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "w18p",
                                          attrs: {
                                            title:
                                              childItem.allocationPackages +
                                              "包  " +
                                              childItem.allocationQuantityPerPackage +
                                              "米/包  " +
                                              childItem.allocationQuantity +
                                              "米"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                childItem.allocationPackages ==
                                                  null
                                                  ? "0"
                                                  : childItem.allocationPackages
                                              ) +
                                              " 包  \n                  " +
                                              _vm._s(
                                                childItem.allocationQuantityPerPackage
                                                  ? childItem.allocationQuantityPerPackage
                                                  : "0"
                                              ) +
                                              " 米/包  \n                  " +
                                              _vm._s(
                                                childItem.allocationQuantity
                                                  ? childItem.allocationQuantity
                                                  : "0"
                                              ) +
                                              " 米\n                "
                                          )
                                        ]
                                      ),
                                      _c("li", { staticClass: "w13p" }, [
                                        _vm._v(
                                          _vm._s(childItem.virtualInventoryName)
                                        )
                                      ]),
                                      _c("li", { staticClass: "w18p" }, [
                                        _vm._v(
                                          _vm._s(childItem.subInventoryName)
                                        )
                                      ]),
                                      _c("li", { staticClass: "w13p" }, [
                                        _vm._v(_vm._s(childItem.saleOrderId))
                                      ]),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "w13p",
                                          attrs: { title: childItem.remark }
                                        },
                                        [_vm._v(_vm._s(childItem.remark))]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          }),
                          _vm.requestList == ""
                            ? _c("p", { staticClass: "empty_tip" }, [
                                _vm._v("暂无数据")
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    }),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "预收货清单", name: "5" } },
                      [
                        _c(
                          "ul",
                          { staticClass: "childlist" },
                          _vm._l(_vm.receiptFieldList, function(item, index) {
                            return _c("li", { key: index, class: item.class }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          }),
                          0
                        ),
                        _vm._l(_vm.receiptList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "allparentlist" },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass: "parentlist",
                                  on: {
                                    click: function($event) {
                                      return _vm.showlist(index, item)
                                    }
                                  }
                                },
                                [
                                  _c("li", [
                                    _c("span", [
                                      _vm._v(_vm._s(item.updateTime))
                                    ])
                                  ]),
                                  _c("li", [
                                    _c("i", [
                                      _vm._v(
                                        "\n                    预收货清单编号:\n                    "
                                      ),
                                      _c("em", [
                                        _vm._v(_vm._s(item.prepareReceiptCode))
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "li",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "margin-right": "15px"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.showlist(index, item)
                                        }
                                      }
                                    },
                                    [
                                      item.ifshowlist == true
                                        ? _c("i", {
                                            staticClass: "el-icon-caret-top"
                                          })
                                        : _c("i", {
                                            staticClass: "el-icon-caret-bottom"
                                          })
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    { staticStyle: { float: "right" } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-edit",
                                        attrs: {
                                          type: "primary",
                                          title: "修改"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editInfoHandle(
                                              index,
                                              item
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._l(item.receiptListItemLists, function(
                                childItem,
                                index
                              ) {
                                return _c(
                                  "ul",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.ifshowlist == true,
                                        expression: "item.ifshowlist==true"
                                      }
                                    ],
                                    key: index,
                                    staticClass: "childcontentlist"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: { title: childItem.productCode }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.productCode
                                              ? childItem.productCode
                                              : "无"
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: { title: childItem.materialCode }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.materialCode
                                              ? childItem.materialCode
                                              : "无"
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: { title: childItem.supplierName }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.supplierName
                                              ? childItem.supplierName
                                              : "无"
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: {
                                          title: childItem.confirmingCode
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.confirmingCode
                                              ? childItem.confirmingCode
                                              : "无"
                                          )
                                        )
                                      ]
                                    ),
                                    _c("li", { staticClass: "w13p" }, [
                                      _vm._v(
                                        _vm._s(
                                          childItem.factoryAllocationCreateTime
                                            ? childItem.factoryAllocationCreateTime
                                            : "无"
                                        )
                                      )
                                    ]),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w18p",
                                        attrs: {
                                          title:
                                            (childItem.transferBagQuantity
                                              ? childItem.transferBagQuantity
                                              : "0") +
                                            "包 " +
                                            (childItem.packSpecification
                                              ? childItem.packSpecification
                                              : "0") +
                                            "米/包 " +
                                            (childItem.transferMeter
                                              ? childItem.transferMeter
                                              : "0") +
                                            "米"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (childItem.transferBagQuantity
                                              ? childItem.transferBagQuantity
                                              : "0") + "包 "
                                          ) +
                                            _vm._s(
                                              (childItem.packSpecification
                                                ? childItem.packSpecification
                                                : "0") + "米/包 "
                                            ) +
                                            _vm._s(
                                              (childItem.transferMeter
                                                ? childItem.transferMeter
                                                : "0") + "米"
                                            )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: { title: childItem.subInventory }
                                      },
                                      [_vm._v(_vm._s(childItem.subInventory))]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: {
                                          title: childItem.batchQuantity
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.batchQuantity
                                              ? childItem.batchQuantity
                                              : 0
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "w13p",
                                        attrs: { title: childItem.batchNumber }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            childItem.batchNumber
                                              ? childItem.batchNumber
                                              : "无"
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        }),
                        !this.receiptList.length
                          ? _c("p", { staticClass: "empty_tip" }, [
                              _vm._v("暂无数据")
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "paging",
                    staticStyle: {
                      "margin-top": "20px",
                      float: "right",
                      height: "30px"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.currentPage,
                            "page-sizes": [10, 50],
                            "page-size": _vm.pageSize,
                            layout: "sizes,total, prev, pager, next",
                            total: this.allpage
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                            "update:currentPage": function($event) {
                              _vm.currentPage = $event
                            },
                            "update:current-page": function($event) {
                              _vm.currentPage = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      this.ifaddsure == true
        ? _c("div", { staticClass: "addcontent" }, [
            _c(
              "div",
              {
                staticClass: "addcontent_title",
                staticStyle: { display: "flex" }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "el-icon-back",
                    attrs: { title: "返回" },
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("返回")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "type",
                    staticStyle: { display: "inline-block", width: "350px" }
                  },
                  [
                    _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("需求来源:")
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.allocationType,
                            expression: "allocationType",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "selectstatus",
                        class: { disactive: this.ifpulldate != true },
                        attrs: { disabled: this.ifpulldate != true },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.allocationType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.selectclick()
                            }
                          ]
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("--请选择需求来源--")
                        ]),
                        _vm._l(_vm.typeList, function(item) {
                          return _c(
                            "option",
                            {
                              key: item.dictItemValue,
                              staticStyle: { color: "#606266" },
                              domProps: { value: item.dictItemValue }
                            },
                            [_vm._v(_vm._s(item.dictItemName))]
                          )
                        })
                      ],
                      2
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "button",
                    staticStyle: { margin: "0 10px" },
                    attrs: { s: "" }
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "primary",
                        title: "拉取数据",
                        icon: "el-icon-refresh-right",
                        circle: ""
                      },
                      on: { click: _vm.pulldata }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "button" },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-plus",
                        circle: "",
                        title: "添加子项"
                      },
                      on: { click: _vm.addchildmsg }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "addcontent_content" }, [
              _vm._m(0),
              _vm.ifchildshow == true
                ? _c(
                    "div",
                    { staticClass: "formtable" },
                    _vm._l(this.purchaseobj.faItemList, function(item, index) {
                      return _c(
                        "ul",
                        { key: index, staticClass: "demo-form-inline" },
                        [
                          _c(
                            "li",
                            { staticStyle: { width: "120px" } },
                            [
                              item.iftext == true
                                ? _c(
                                    "span",
                                    { attrs: { title: item.productCode } },
                                    [_vm._v(_vm._s(item.productCode))]
                                  )
                                : _c("el-input", {
                                    attrs: {
                                      title: item.productCode,
                                      onkeyup:
                                        "this.value=this.value.replace(/[^\\d.]/g,'');",
                                      maxlength: "10",
                                      autocomplete: "off"
                                    },
                                    model: {
                                      value: item.productCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "productCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.productCode"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticStyle: { width: "148px" } },
                            [
                              item.iftext == true
                                ? _c(
                                    "span",
                                    {
                                      attrs: { title: item.expectedArrivalDate }
                                    },
                                    [_vm._v(_vm._s(item.expectedArrivalDate))]
                                  )
                                : _c("el-date-picker", {
                                    attrs: {
                                      title: item.expectedArrivalDate,
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "请选择日期",
                                      "picker-options": _vm.pickerOptions0
                                    },
                                    model: {
                                      value: item.expectedArrivalDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "expectedArrivalDate",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.expectedArrivalDate"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c("li", { staticStyle: { width: "365px" } }, [
                            item.iftext == true
                              ? _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        item.allocationPackages
                                          ? item.allocationPackages
                                          : "0"
                                      ) +
                                      " 包  \n                " +
                                      _vm._s(
                                        item.allocationQuantityPerPackage
                                          ? item.allocationQuantityPerPackage
                                          : "0"
                                      ) +
                                      " 米/包  \n                " +
                                      _vm._s(
                                        item.allocationQuantity
                                          ? item.allocationQuantity
                                          : "0"
                                      ) +
                                      " 米\n              "
                                  )
                                ])
                              : _c(
                                  "div",
                                  { attrs: { id: "" } },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        size: "mini",
                                        title: item.allocationPackages,
                                        "controls-position": "right",
                                        min: 0,
                                        "step-strictly": ""
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value: item.allocationPackages,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "allocationPackages",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "item.allocationPackages"
                                      }
                                    }),
                                    _c("span", [_vm._v("包")]),
                                    _c("el-input-number", {
                                      attrs: {
                                        size: "mini",
                                        precision: 2,
                                        title:
                                          item.allocationQuantityPerPackage,
                                        "controls-position": "right",
                                        min: 0
                                      },
                                      on: { change: _vm.handleChange },
                                      model: {
                                        value:
                                          item.allocationQuantityPerPackage,
                                        callback: function($$v) {
                                          _vm.$set(
                                            item,
                                            "allocationQuantityPerPackage",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "item.allocationQuantityPerPackage"
                                      }
                                    }),
                                    _c("span", [_vm._v("米/包")]),
                                    _c(
                                      "em",
                                      {
                                        staticStyle: { display: "inline-flex" },
                                        attrs: {
                                          title:
                                            item.allocationPackages *
                                            item.allocationQuantityPerPackage
                                              ? item.allocationPackages *
                                                item.allocationQuantityPerPackage
                                              : 0
                                        },
                                        model: {
                                          value: (item.allocationQuantity =
                                            item.allocationPackages *
                                            item.allocationQuantityPerPackage),
                                          callback: function($$v) {
                                            _vm.$set(
                                              (item.allocationQuantity =
                                                item.allocationPackages * item),
                                              "allocationQuantityPerPackage",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "item.allocationQuantity=item.allocationPackages*item.allocationQuantityPerPackage"
                                        }
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticStyle: {
                                              "max-width": "80px",
                                              "white-space": "nowrap",
                                              "text-overflow": "ellipsis",
                                              overflow: "hidden"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                \t\t\t" +
                                                _vm._s(
                                                  item.allocationPackages *
                                                    item.allocationQuantityPerPackage
                                                    ? item.allocationPackages *
                                                        item.allocationQuantityPerPackage
                                                    : 0
                                                ) +
                                                "\n                \t\t"
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "0" } },
                                      [_vm._v("米")]
                                    )
                                  ],
                                  1
                                )
                          ]),
                          _c("li", { staticStyle: { width: "190px" } }, [
                            _vm._v(_vm._s(item.allocationTypeName))
                          ]),
                          _c("li", { staticStyle: { width: "85px" } }, [
                            item.iftext == true
                              ? _c("span", [
                                  _vm._v(_vm._s(item.virtualInventoryName))
                                ])
                              : _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: item.virtualInventoryId,
                                        expression: "item.virtualInventoryId",
                                        modifiers: { trim: true }
                                      }
                                    ],
                                    staticClass: "selectstatus",
                                    staticStyle: { width: "85px" },
                                    attrs: { placeholder: "请选择仓位" },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            item,
                                            "virtualInventoryId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.selectwearclick(item)
                                        }
                                      ]
                                    }
                                  },
                                  _vm._l(_vm.warehouseList, function(i) {
                                    return _c(
                                      "option",
                                      {
                                        key: i.index,
                                        staticStyle: { color: "#606266" },
                                        domProps: { value: i.index }
                                      },
                                      [_vm._v(_vm._s(i.name))]
                                    )
                                  }),
                                  0
                                )
                          ]),
                          _c("li", { staticStyle: { width: "135px" } }, [
                            item.iftext == true
                              ? _c("span", [
                                  _vm._v(_vm._s(item.subInventoryName))
                                ])
                              : _c("div", { attrs: { id: "" } }, [
                                  item.ifsubInventory == true
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: item.subInventory,
                                              expression: "item.subInventory",
                                              modifiers: { trim: true }
                                            }
                                          ],
                                          staticClass: "selectstatus",
                                          staticStyle: { width: "135px" },
                                          attrs: { placeholder: "请选择库位" },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  item,
                                                  "subInventory",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function($event) {
                                                return _vm.subinventoryclick(
                                                  item
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        _vm._l(item.subinventoryList, function(
                                          item
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: item.subInventory,
                                              staticStyle: { color: "#606266" },
                                              domProps: {
                                                value: item.subInventory
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.subInventoryName)
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(item.subInventoryName))
                                      ])
                                ])
                          ]),
                          _c(
                            "li",
                            { staticStyle: { width: "115px" } },
                            [
                              item.iftext == true
                                ? _c("span", [
                                    _vm._v(_vm._s(item.specialRequire))
                                  ])
                                : _c("el-input", {
                                    attrs: {
                                      title: item.specialRequire,
                                      maxlength: "63",
                                      autocomplete: "off"
                                    },
                                    model: {
                                      value: item.specialRequire,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "specialRequire",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.specialRequire"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticStyle: { width: "140px" } },
                            [
                              item.iftext == true
                                ? _c(
                                    "span",
                                    { attrs: { title: item.remark } },
                                    [_vm._v(_vm._s(item.remark))]
                                  )
                                : _c("el-input", {
                                    attrs: {
                                      title: item.remark,
                                      maxlength: "127",
                                      placeholder: "请输入备注",
                                      autocomplete: "off"
                                    },
                                    model: {
                                      value: item.remark,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "remark",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.remark"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticStyle: { width: "70px" } },
                            [
                              item.iftext == true
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.isTransport == true ? "是" : "否"
                                      )
                                    )
                                  ])
                                : _c("el-switch", {
                                    attrs: {
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949"
                                    },
                                    model: {
                                      value: item.isTransport,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "isTransport",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "item.isTransport"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticStyle: { width: "42px" } },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  title: "删除",
                                  icon: "el-icon-minus",
                                  circle: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.childdel(index, item)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    {
                      staticClass: "formtable_null",
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "15px"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "200px", height: "100px" },
                        attrs: { src: require("../../../assets/image/add.png") }
                      }),
                      _c("p", [
                        _vm._v(
                          "\n            信息\n            空空如也去选择需求来源\n          "
                        )
                      ])
                    ]
                  )
            ]),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                staticStyle: {
                  float: "right",
                  "margin-top": "45px",
                  "margin-right": "20px"
                },
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      loading: _vm.loadingsave,
                      disabled: _vm.ifchildshow != true
                    },
                    on: {
                      click: function($event) {
                        return _vm.draftsave("save")
                      }
                    }
                  },
                  [_vm._v("保存为草稿")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.loadingcommit,
                      disabled: _vm.ifchildshow != true
                    },
                    on: {
                      click: function($event) {
                        return _vm.draftsave("commit")
                      }
                    }
                  },
                  [_vm._v("提交审批")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogdelVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogcommitVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogcommitVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否确认提交审批?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogcommitVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibcmtclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10" },
                [
                  _c("el-button", {
                    staticClass: "mr10",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-download",
                      circle: "",
                      title: "下载"
                    },
                    on: { click: _vm.downloadPackingHandle }
                  }),
                  _c("span", [_vm._v("下载箱单")])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib" },
                [
                  _c("el-button", {
                    staticClass: "mr10",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-download",
                      circle: "",
                      title: "下载"
                    },
                    on: { click: _vm.downloadInvoiceHandle }
                  }),
                  _c("span", [_vm._v("下载工厂发票")])
                ],
                1
              ),
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.popupFieldNameList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.confirmReceiptList, function(item, index) {
                    return _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.batchNumber,
                            expression: "item.batchNumber"
                          }
                        ],
                        key: index,
                        staticClass: "childcontentlist"
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "w13p",
                            attrs: { title: item.productCode }
                          },
                          [
                            _vm._v(
                              _vm._s(item.productCode ? item.productCode : "无")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w13p",
                            attrs: { title: item.confirmingCode }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.confirmingCode ? item.confirmingCode : "无"
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w14p",
                            attrs: { title: item.batchNumber }
                          },
                          [
                            _vm._v(
                              _vm._s(item.batchNumber ? item.batchNumber : "无")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w16p",
                            attrs: {
                              title: item.transferBagQuantity
                                ? item.transferBagQuantity
                                : 0
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.transferBagQuantity
                                  ? item.transferBagQuantity
                                  : 0
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w16p",
                            attrs: {
                              title:
                                (item.transferBagQuantity
                                  ? item.transferBagQuantity
                                  : "0") +
                                "包 " +
                                (item.packSpecification
                                  ? item.packSpecification
                                  : "0") +
                                "米/包 " +
                                (item.transferMeter
                                  ? item.transferMeter
                                  : "0") +
                                "米"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                (item.transferBagQuantity
                                  ? item.transferBagQuantity
                                  : "0") + "包 "
                              ) +
                                _vm._s(
                                  (item.packSpecification
                                    ? item.packSpecification
                                    : "0") + "米/包 "
                                ) +
                                _vm._s(
                                  (item.transferMeter
                                    ? item.transferMeter
                                    : "0") + "米"
                                )
                            )
                          ]
                        ),
                        _c("li", { staticClass: "w16p" }, [
                          _vm._v(_vm._s(item.updateTime))
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c("span", { staticClass: "add_btn" }, [
                    _vm._v("新增预收货清单记录")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "edit_btn",
                      on: { click: _vm.batchEditHandle }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus",
                        staticStyle: {
                          "margin-right": "10px",
                          "vertical-align": "middle"
                        },
                        attrs: { type: "primary", title: "" }
                      }),
                      _vm._v("批量编辑\n            ")
                    ]
                  )
                ]),
                _c("div", { staticClass: "add_warp" }, [
                  _c(
                    "ul",
                    { staticClass: "childlist" },
                    _vm._l(_vm.editFieldNameList, function(item, index) {
                      return _c("li", { key: index, class: item.class }, [
                        item.isRules
                          ? _c("span", { staticClass: "red mr5" }, [
                              _vm._v("*")
                            ])
                          : _vm._e(),
                        _vm._v(
                          "\n                " +
                            _vm._s(item.name) +
                            "\n              "
                        )
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "allparentlist" },
                    _vm._l(_vm.editReceiptList, function(item, index) {
                      return _c(
                        "ul",
                        { key: index, staticClass: "childcontentlist" },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "w15p",
                              attrs: { title: item.productCode }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.productCode ? item.productCode : "无"
                                )
                              )
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "w15p",
                              attrs: { title: item.batchNumber }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.confirmingCode
                                    ? item.confirmingCode
                                    : "无"
                                )
                              )
                            ]
                          ),
                          _c(
                            "li",
                            { staticClass: "w16p" },
                            [
                              _c("el-input", {
                                ref: "batchNumInput" + item.id,
                                refInFor: true,
                                staticClass: "input_single",
                                attrs: { placeholder: "批次号" },
                                model: {
                                  value: item.batchNumber,
                                  callback: function($$v) {
                                    _vm.$set(item, "batchNumber", $$v)
                                  },
                                  expression: "item.batchNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "w20p fr" },
                            [
                              !item.isChildPack && _vm.isShowAdd
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.addItemEdit(index, item)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-circle-plus",
                                        staticStyle: {
                                          "margin-right": "10px",
                                          "vertical-align": "middle"
                                        },
                                        attrs: { type: "primary", title: "" }
                                      })
                                    ]
                                  )
                                : _c("span", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "38px",
                                      height: "28px"
                                    }
                                  }),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: item.transferBagQuantity === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.submitHandle(index, item)
                                    }
                                  }
                                },
                                [_vm._v("确认")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "content_form",
        staticStyle: { "text-align": "center", padding: "0px 10px" }
      },
      [
        _c("li", { staticStyle: { width: "98px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("产品编号\n          ")
        ]),
        _c("li", { staticStyle: { width: "145px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("预计到货时间\n          ")
        ]),
        _c("li", { staticStyle: { width: "345px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("调拨数量\n          ")
        ]),
        _c("li", { staticStyle: { width: "170px" } }, [_vm._v("需求来源")]),
        _c("li", { staticStyle: { width: "85px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("仓位\n          ")
        ]),
        _c("li", { staticStyle: { width: "135px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("库位\n          ")
        ]),
        _c("li", { staticStyle: { width: "115px" } }, [
          _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
          _vm._v("特殊要求\n          ")
        ]),
        _c("li", { staticStyle: { width: "140px" } }, [_vm._v("备注")]),
        _c("li", { staticStyle: { width: "70px" } }, [_vm._v("是否可发运")]),
        _c("li", { staticStyle: { width: "42px" } }, [_vm._v("操作")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }